import { useConstants } from '../../hooks/useConstants'
import { formatUnixTimestamp } from '../../utils/formatUnixTimestamp'
import { getShortAddress } from '../../utils/getShortAddress'

import { VITE_EXPLORER_URL } from '../../constants/config'
import CapacityCommitmentGQL from '../../store/CapacityCommitmentGQL'
import { A } from '../A'
import { Copyable } from '../Copyable'
import { Flex } from '../Flex'
import HintedText from '../HintedText'
import { Text } from '../Text'

import { CapacityCommitmentInformation, InformationRow } from './styled'

export function CommitmentInfo({
  capacityCommitment,
}: {
  capacityCommitment: CapacityCommitmentGQL
}) {
  const constants = useConstants()

  if (!constants) return null

  return (
    <CapacityCommitmentInformation>
      <Text size={16}>Commitment information</Text>
      <InformationRow>
        <HintedText text="Commitment ID" />
        <Flex alignItems="center">
          <A
            size={12}
            href={`${VITE_EXPLORER_URL}/capacity/${capacityCommitment.id}`}
          >
            {getShortAddress(capacityCommitment.id, 16)}
          </A>
          <Copyable value={capacityCommitment.id} />
        </Flex>
      </InformationRow>
      <InformationRow>
        <HintedText text="Peer ID" />
        <Flex alignItems="center">
          <A
            size={12}
            href={`${VITE_EXPLORER_URL}/peer/${capacityCommitment.peer.id}`}
          >
            {getShortAddress(capacityCommitment.peer.id ?? '', 16)}
          </A>
          <Copyable value={capacityCommitment.peer.id ?? ''}></Copyable>
        </Flex>
      </InformationRow>
      <InformationRow>
        <HintedText text="Created At" />
        <Text size={12}>
          {
            formatUnixTimestamp(
              constants.calculateTimestamp(capacityCommitment.startEpoch),
            ).date
          }
        </Text>
      </InformationRow>
      <InformationRow>
        <HintedText
          text="Compute capacity"
          hint="Number of CUs this CC contributes to the network. (1 CU = 1 CPU core + 4 GB RAM)"
        />
        <Text size={12}>{capacityCommitment.computeUnitsCount} CUs</Text>
      </InformationRow>
    </CapacityCommitmentInformation>
  )
}
