import { useContext } from 'react'
import { useController } from '@data-client/react'
import * as Sentry from '@sentry/react'
import { ContractTransactionResponse, JsonRpcSigner } from 'ethers'

import { getShortAddress } from '../../utils/getShortAddress'

import { stakeCollateralCapacityCommitment } from '../../store/stakeCollateralCapacityCommitment'
import { StakeModal } from '../Modals/StakeModal'
import { TransactionWaitingModal } from '../Modals/TransactionWaitingModal'
import { SignerContext } from '../SignerProvider'
import { StakeModalContext } from '../StakeModalProvider'

import TransactionFailedModal from './TransactionFailedModal'
import TransactionSuccessModal from './TransactionSuccessModal'

export type StakingStatus = 'waiting' | 'pending' | 'success' | 'failed'

function StakeModalContainer({
  id,
  onClose,
  onChangeStatus,
  signer,
}: {
  signer: JsonRpcSigner
  id: string
  onClose: () => void
  onChangeStatus: (
    status: StakingStatus,
    payload?: { transactionHash: string },
  ) => void
}) {
  const ctrl = useController()

  async function onStake() {
    try {
      onChangeStatus('pending')
      const { transaction } = await ctrl.fetch(
        stakeCollateralCapacityCommitment,
        {
          capacityCommitmentId: id,
          signer,
        },
      )
      onChangeStatus('success', {
        transactionHash: (transaction as ContractTransactionResponse)?.hash,
      })
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
      onChangeStatus('failed')
    }
  }

  return <StakeModal id={id} isVisible onClose={onClose} onStake={onStake} />
}

export interface Staking {
  status: StakingStatus
  id: string
  transactionHash?: string
}

export const StakingModals = () => {
  const { signer } = useContext(SignerContext)
  const { data: staking, setCurrentId, setData } = useContext(StakeModalContext)

  function onClose() {
    setCurrentId('')
  }

  function onChangeStatus(
    status: StakingStatus,
    payload?: { transactionHash: string },
  ) {
    if (!staking) return

    setData({
      ...staking,
      ...payload,
      status,
    })
  }

  if (!signer) return

  return (
    <>
      {staking?.status === 'waiting' ? (
        <StakeModalContainer
          {...staking}
          onClose={onClose}
          onChangeStatus={onChangeStatus}
          signer={signer}
        />
      ) : staking?.status === 'pending' ? (
        <TransactionWaitingModal
          onClose={onClose}
          isVisible
          description={`Capacity commitment ID: ${getShortAddress(staking.id, 12)}`}
        />
      ) : staking?.status === 'success' ? (
        <TransactionSuccessModal
          id={staking.id}
          onClose={onClose}
          transactionHash={staking.transactionHash}
        />
      ) : (
        staking?.status === 'failed' && (
          <TransactionFailedModal onClose={onClose} />
        )
      )}
    </>
  )
}
