import { Endpoint } from '@data-client/rest'
import { Contracts } from '@fluencelabs/deal-ts-clients'
import { JsonRpcSigner } from 'ethers'

import { renderJsonString } from '../utils/renderJsonString'

import { DEPLOYMENT } from '../constants/config'

import CapacityCommitmentGQL, {
  CommitmentLifecycleStatus,
} from './CapacityCommitmentGQL'

export const withdrawCollateralCapacityCommitment = new Endpoint(
  async ({
    capacityCommitmentId,
    signer,
  }: {
    capacityCommitmentId: string
    signer: JsonRpcSigner
  }) => {
    const contracts = new Contracts(signer, DEPLOYMENT)

    const tx = await contracts.diamond.withdrawCollateral(
      capacityCommitmentId,
      {
        maxPriorityFeePerGas: 0,
      },
    )

    await tx.wait()

    return {
      id: capacityCommitmentId,
      collateralWithdrawn: true,
      status: CommitmentLifecycleStatus.Withdrawn,
    }
  },
  {
    key: (params: unknown) =>
      `withdrawCollateralCapacityCommitment ${renderJsonString(params)}`,
    name: 'withdrawCollateralCapacityCommitment',
    schema: CapacityCommitmentGQL,
  },
)
