import { GQLEntity } from '@data-client/graphql'

import { fixedPrecisionValueToBig } from '../utils/fixedPrecisionValueToBig'
import { nativeTokenToBig } from '../utils/nativeTokenToBig'

export const STATUS_NAMES = {
  Unknown: 'Unknown',
  WaitDelegation: 'Created',
  WaitStart: 'Funded',
  Active: 'Active',
  Inactive: 'Completed',
  Failed: 'Failed',
  Removed: 'Removed',
  Withdrawn: 'Withdrawn',
}

export enum CommitmentLifecycleStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  Failed = 'Failed',
  Inactive = 'Inactive',
  Removed = 'Removed',
  WaitDelegation = 'WaitDelegation',
  WaitStart = 'WaitStart',
  Withdrawn = 'Withdrawn',
}
export default class CapacityCommitmentGQL extends GQLEntity {
  readonly startEpoch!: number
  readonly endEpoch!: number

  readonly provider!: {
    id: string
    name: string
    approved: boolean
    totalCCs: number
    createdAt: number
  }

  readonly status: CommitmentLifecycleStatus = CommitmentLifecycleStatus.Unknown
  readonly rewardDelegatorRate!: Big
  readonly requiredCollateral!: Big
  readonly delegator?: { id: string }
  readonly duration!: number
  readonly rewardRatePerCollateral!: Big
  readonly computeUnitsCount!: number
  readonly totalFailCount!: number

  get stakingReward() {
    return this.rewardDelegatorRate
  }

  get staker() {
    return this.delegator?.id.toLowerCase()
  }

  readonly peer!: {
    id: string
  }

  static schema = {
    requiredCollateral: nativeTokenToBig,
    rewardDelegatorRate: fixedPrecisionValueToBig,
    rewardRatePerCollateral: fixedPrecisionValueToBig,
    duration: Number,
  }

  get statusName() {
    return STATUS_NAMES[this.status]
  }
}
