import { Entity } from '@data-client/rest'
import { RawRewardsData } from '@fluencelabs/deal-ts-clients/dist/dealExplorerClient/types/schemes'
import Big from 'big.js'

import { nativeTokenToBig } from '../utils/nativeTokenToBig'

function splitRewards(
  { ccRewards, dealStakerRewards }: RawRewardsData,
  stakerRate: Big,
) {
  const ccRewardsNative = nativeTokenToBig(ccRewards)
  const dealStakerRewardsNative = nativeTokenToBig(dealStakerRewards)
  const stakerCCReward = ccRewardsNative.times(stakerRate)

  return {
    provider: ccRewardsNative.minus(stakerCCReward),
    staker: stakerCCReward.plus(dealStakerRewardsNative),
  }
}

export class CapacityCommitmentRewards extends Entity {
  readonly id!: string
  readonly stakingReward!: Big

  pk() {
    return this.id.toLowerCase()
  }

  readonly inVesting!: Big
  readonly availableToClaim!: Big
  readonly claimed!: Big

  static process({
    totalRewards,
    withdrawnRewards,
    unlockedRewards,
    stakingReward,
    ...rest
  }: {
    stakingReward: Big
    totalRewards?: RawRewardsData
    unlockedRewards?: RawRewardsData
    withdrawnRewards?: RawRewardsData
  }) {
    const claimed = withdrawnRewards
      ? splitRewards(withdrawnRewards, stakingReward).staker
      : Big(0)

    const availableToClaim = unlockedRewards
      ? splitRewards(unlockedRewards, stakingReward).staker
      : Big(0)

    const totalStakerRewards = totalRewards
      ? splitRewards(totalRewards, stakingReward).staker
      : Big(0)

    const inVesting = totalStakerRewards.minus(availableToClaim)

    return {
      ...rest,
      claimed,
      availableToClaim,
      inVesting,
    }
  }
}
